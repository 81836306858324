import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Seo from '../components/Seo/Seo';
import Image from '../components/Image/Image';
import { renderRichText } from "gatsby-source-contentful/rich-text";
import '../scss/main.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";

const formatDate = (string) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const date = new Date(string);
  const dateString = `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  return dateString;
}



const BlogPostTemplate = ({data}) => {
    const { title, publishDate, description, image } = data.contentfulPosts;
    const pageTitle = data.contentfulPages.title;
    const [loaded, setLoaded] = useState('');
    
    const shareFacebook = () => {
      const url = typeof window !== 'undefined' ? window.location.href : '';
      const network_url = "https://www.facebook.com/sharer/sharer.php?u="+encodeURIComponent(url);
      window.open(network_url, "facebook_share", "width=600, height=400");

      console.log('facebook network_url ========= '+network_url);
    }
    const shareTwitter = () => {
    
      const url = typeof window !== 'undefined' ? window.location.href : '';
      const hashtag = 'SaysLife';
      const character_max = 240;
      let characters_over = 0;
      let message_string = title;
      let titleString = title;
      console.log("title.length = "+title.length+" > "+character_max);
      console.log("title = "+title);
    
      if(title.length > character_max) {
          characters_over = title.length + hashtag.length - character_max  + 3;
          titleString = title.substring(0, title.length - characters_over);
          titleString = titleString +"..."
          console.log("titleString = "+titleString+" characters_over = "+characters_over+" message_string.length = "+message_string.length)
      }

      titleString = titleString +" %23"+hashtag;
      console.log('twitter url ========= '+url);
    
      const network_url = "http://twitter.com/share?text="+titleString+"&url="+url;
    
      console.log('twitter network_url ========= '+network_url);
    
      window.open(network_url, "twitter_share", "width=600, height=400");
    }
    return (
      <>
      <Seo data={data?.contentfulPosts}/>
      <Header />
      <main className={`post ${loaded}`}>
        {pageTitle && <h1>{pageTitle}</h1>}
        <div className='content'>
          {image && <Image data={image} onLoad={() => setLoaded('loaded')}/>}
          <h2>{title}</h2>
          <div className='social'>
            <FontAwesomeIcon icon={faFacebook} onClick={shareFacebook}/>
            <FontAwesomeIcon icon={faTwitter} onClick={shareTwitter}/>
          </div>
          {publishDate && <h3>{formatDate(publishDate)}</h3>}
          {description && <div className='page-desc'>
            {renderRichText(description)}
          </div>}
        </div>{/* content */}      
      </main>
      <Footer/>
      </>
    )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    contentfulPosts(slug: { eq: $slug }) {
      description {
        raw
      }
      image {
        file {
          url
        }
      }
      slug
      title
      excerpt
      publishDate
      metaTitle
      metaDesc
      metaImage {
        file {
          url
        }
      }
    }
    contentfulPages(slug: { eq: "posts" }) {
      slug
      title
      metaTitle
      metaDesc
      metaImage {
        file {
          url
        }
      }
      image {
        file {
          url
        }
      }
      headline
      description {
        raw
      }
    }
  }
`
